import Service from "utils/HttpClient";
import { config } from "constant/config";

export const getAllEmployees = async () => {
  return await Service.getApi(config.API_URL, "/employees/getAllEmployees");
};
export const getAllDesignation = async () => {
  return await Service.getApi(config.API_URL, "/employees/getAllDesignation");
};
export const addDesignation = async (body) => {
  return await Service.postApi(config.API_URL, "/employees/addDesignation", body);
};


export const uploadUserimage = async (body) =>{
  return await Service.postApi(config.API_URL, "/employees/uploadphoto", body);
 }
export const addUserData = async (body) =>{
 return await Service.postApi(config.API_URL, "/employees/createEmployee", body);
}

export const editserData = async (body) =>{
  return await Service.postApi(config.API_URL, "/employees/updateEmployee", body);
 }
export const deleteApi = async (body) =>{
  return await Service.postApi(config.API_URL, "/employees/deleteEmployee", body);
 }
 export const getEmployeeById = async (employeeid) => {
  return await Service.getApi(config.API_URL, `/employees/getEmployeeById?eid=${employeeid}`);
};
export const getClientById = async (clientid) => {
  return await Service.getApi(config.API_URL, `/clients/getClientById?cid=${clientid}`);
};

