import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "antd";
import { getEmployeeById, getClientById } from "api/employee";
import { startLoading, stopLoading } from "Routes/slice";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import "./style.scss";

const MainHeader = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);

  const fetchEmployeeData = async (employeeid) => {
    try {
      dispatch(startLoading());
      const response = await getEmployeeById(employeeid);
      setEmployeeDetails(response);
      setIsModalVisible(true);
    } catch (error) {
      console.error(error);
      addToast(error.message || "Error fetching employee data", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      dispatch(stopLoading());
    }
  };

  const fetchClientData = async (clientid) => {
    try {
      dispatch(startLoading());
      const response = await getClientById(clientid);
      setClientDetails(response);
      setIsModalVisible(true);
    } catch (error) {
      console.error(error);
      addToast(error.message || "Error fetching client data", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleMenuClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleProfileClick = () => {
    if (userData && userData.id) {
      if (userData.role === "EMPLOYEE") {
        fetchEmployeeData(userData.id);
      } else if (userData.role === "CLIENT") {
        fetchClientData(userData.id);
      }
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="main_header">
      <div className="menus">
        <div className="account-menu">
          <div className="menulist" onClick={handleMenuClick}>
            <i className="riot-icon riot-icon-expencess" />
            {userData && userData.firstname ? userData.firstname : "Account"}
          </div>

          {isDropdownVisible && (
            <div className="dropdown-menu">
              {userData && userData.role !== "CLIENT" && userData.role !== "EMPLOYEE" && (
                <>
                  <Link to="/projects">Projects</Link>
                  <Link to="/client">Client</Link>
                  <Link to="/employee">User</Link>
                </>
              )}
              <div onClick={handleProfileClick} style={{ padding: "10px" }}>
                Profile
              </div>
              <Link to="/login">Logout</Link>
            </div>
          )}
        </div>
      </div>

      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <Button key="close" onClick={handleModalCancel}>
            Close
          </Button>,
        ]}
        className="profile-modal"
      >
        {userData.role === "EMPLOYEE" && employeeDetails ? (
          <div className="employee-details">
            {/* Profile Image */}
            <div className="profile-header">
              <img
                src={employeeDetails.imageURl}
                alt={employeeDetails.empname}
                className="profile-image"
              />
              <h2 className="employee-name">{employeeDetails.empname}</h2>
              <p className="employee-designation">{employeeDetails.designation}</p>
            </div>

            {/* Profile Information */}
            <div className="profile-info">
              <p><strong>Employee Code:</strong> {employeeDetails.empcode}</p>
              <p><strong>Email:</strong> {employeeDetails.email}</p>
              <p><strong>Mobile:</strong> {employeeDetails.mobile}</p>
              <p><strong>Date of Joining:</strong> {employeeDetails.dateofjoining}</p>
            </div>
          </div>
        ) : userData.role === "CLIENT" && clientDetails ? (
          <div className="client-details">
            {/* Client Profile - Implement similar structure if required */}
            <div className="profile-header">
              <img
                src={clientDetails.imageURl}
                alt={clientDetails.clientname}
                className="profile-image"
              />
              <h2 className="employee-name">{clientDetails.clientname}</h2>
              {/* Add any additional client-related fields */}
            </div>
            <div className="profile-info">
              <p><strong>Company Name:</strong> {clientDetails.companyname}</p>
              <p><strong>Contact Person:</strong> {clientDetails.contactperson}</p>
              <p><strong>Email:</strong> {clientDetails.email}</p>
              <p><strong>Mobile:</strong> {clientDetails.contactnumber}</p>
              {/* Add other client details */}
            </div>
          </div>
        ) : (
          <p>Loading details...</p>
        )}
      </Modal>
    </div>
  );
};

export default MainHeader;
