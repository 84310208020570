import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { dateFormat } from "constant";

export function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

export function getDataFromUrl(url) {
  const urlParams = new URLSearchParams(window.location.search);
  const leaseId = urlParams.get("leaseId");
  const data = url.split("/");
  return {
    clientId: data[1],
    projectId: data[2],
    propertyId: data[3],
    leaseId,
  };
}

export const getNewDateFormat = (dateString) => {
  if (dateString && dateString !== "null") {
    const momentObj = moment(dateString, dateFormat);
    return new Date(momentObj);
  }
};

export const convertToCustomFormat = (date) => {
  if (date) {
    return moment(date).format(dateFormat);
  }
  return null;
};

export const cleanObject = (obj) => {
  const newObject = obj;
  for (const propName in newObject) {
    if (
      typeof newObject[`${propName}`] === "string" &&
      !newObject[`${propName}`]
    ) {
      delete newObject[`${propName}`];
    } else if (
      typeof newObject[`${propName}`] === "string" &&
      newObject[`${propName}`]
    ) {
      newObject[`${propName}`] = newObject[`${propName}`].trim();
    }
  }
  return newObject;
};

export const removeEmpty = (obj) => {
  const newObject = obj;
  for (const propName in newObject) {
    if (
      typeof newObject[`${propName}`] === "object" &&
      isEmpty(newObject[`${propName}`])
    ) {
      delete newObject[`${propName}`];
    }
  }
  return newObject;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

// export const disabledStartDate = value => value < new Date('2021-02-29');

export const disabledEndDate = (value, startDate) =>
  value < new Date(startDate);

export const customFormat = (value) => {
  const newDate = moment(value).format(dateFormat);
  if (newDate.toString() === "Invalid date") {
    return null;
  }
  return newDate;
};

export const addSerialNumToList = (arrayData) =>
  arrayData.map((mapData, index) => ({ ...mapData, sNo: index + 1 }));
