import React, { useState } from "react";
import { getEmployeeById } from "api/employee";
import { startLoading, stopLoading } from "Routes/slice";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import "./style.scss";
import "styles/main_table.scss";
import MainHeader from "component/MainHeader";

const Profile = () => {
    const userData = JSON.parse(localStorage.getItem("userData")); 
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [showProfile, setShowProfile] = useState(false);

    const fetchEmployeeData = async (employeeid) => {
        try {
            dispatch(startLoading());
            const response = await getEmployeeById(employeeid);
            setEmployeeDetails(response);
            setShowProfile(true);
        } catch (error) {
            console.error(error);
            addToast(error.message || "Error fetching employee data", {
                appearance: "error",
                autoDismiss: true,
            });
        } finally {
            dispatch(stopLoading());
        }
    };
    const handleProfileClick = () => {
        if (userData && userData.id) {
            fetchEmployeeData(userData.id);
        }
    };

    return (
        <div className="bodytable">
            <div className="page-name">
                <div className="page-name-title">
                    <h1>JOLIASS</h1>
                </div>
                <MainHeader />
            </div>
            <div className="profile-container">
                <h1>Employee Profile</h1>
                <button onClick={handleProfileClick} className="profile-button">
                    Show Profile
                </button>
                {showProfile && employeeDetails ? (
                    <div className="employee-details">
                        <h2>Employee Details</h2>
                        <p><strong>Name:</strong> {employeeDetails.empname}</p>
                        <p><strong>Email:</strong> {employeeDetails.email}</p>
                        <p><strong>Mobile:</strong> {employeeDetails.mobile}</p>
                        <p><strong>Designation:</strong> {employeeDetails.designation}</p>
                        <p><strong>Date of Joining:</strong> {employeeDetails.dateofjoining}</p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Profile;
