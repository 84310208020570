import axios from "axios";
import get from "lodash/get";
// import { useSelector } from 'react-redux';

import { getFormData } from "./commonFunction";

const unAuthorizedApis = ["/login"];

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);
// const token = useSelector((state) => state.app.token);
const defaultHeader = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
};

class HttpClient {
  async getApi(baseUrl, url, config = defaultHeader) {
    try {
      const response = await axios.get(`${baseUrl}${url}`, config);
      if (
        get(response, "data.Message", "") === "Token Authentication Failed !!"
      ) {
        localStorage.clear();
        window.location = "/login";
      }
      return response.data;
    } catch (err) {
      console.error(err.message);
      return err;
    }
  }

  async deleteApi(baseUrl, url, body, isFormData = false) {
    try {
      let newBody = body;
      if (isFormData) {
        newBody = getFormData(body);
      }
      const response = await axios.delete(
        `${baseUrl}${url}`,
        newBody,
        unAuthorizedApis.includes(url) ? {} : defaultHeader
      );
      if (
        get(response, "data.Message", "") === "Token Authentication Failed !!"
      ) {
        localStorage.clear();
        window.location = "/login";
      }
      return response.data;
    } catch (err) {
      console.error(err.message);
      return err;
    }
  }

  async postApi(baseUrl, url, body, isFormData = false) {
    try {
      let newBody = body;
      if (isFormData) {
        newBody = getFormData(body);
      }
      const response = await axios.post(
        `${baseUrl}${url}`,
        newBody,
        unAuthorizedApis.includes(url) ? {} : defaultHeader
      );
      if (
        get(response, "data.Message", "") === "Token Authentication Failed !!"
      ) {
        localStorage.clear();
        window.location = "/login";
      }

      return response.data;
    } catch (err) {
      console.error(err.message);
      return err;
    }
  }


  async userDatapostapi(baseUrl, url, body, token, isFormData = false) {
    const headers = {
      Authorization: `Bearer ${body}`,
    };

    try {
      let newBody = body;
      if (isFormData) {
        newBody = getFormData(body);
      }
      const response = await axios.post(
        `${baseUrl}${url}`,
        newBody,
        unAuthorizedApis.includes(url) ? {} : {
          headers: {
            Authorization: `Bearer ${body}`,
            // "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
          },
        },
      );
      if (
        get(response, "data.Message", "") === "Token Authentication Failed !!"
      ) {
        localStorage.clear();
        window.location = "/login";
      }

      return response.data;
    } catch (err) {
      console.error(err.message);
      return err;
    }
  }
  async putApi(baseUrl, url, body, isFormData = false) {
    try {
      let newBody = body;
      if (isFormData) {
        newBody = getFormData(body);
      }
      const response = await axios.put(
        `${baseUrl}${url}`,
        newBody,
        // defaultHeader
      );
      if (
        get(response, "data.Message", "") === "Token Authentication Failed !!"
      ) {
        localStorage.clear();
        window.location = "/login";
      }

      return response.data;
    } catch (err) {
      console.error(err.message);
      return err;
    }
  }
}

export default new HttpClient();
