import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    appLoading: false,
    userData: JSON.parse(localStorage.getItem("userData")) || {},
    token : localStorage.getItem('userToken'),
    alertData: {},
  },
  reducers: {
    startLoading: (state) => {
      state.appLoading = true;
    },
    stopLoading: (state) => {
      state.appLoading = false;
    },
    updateUserData: (state, actions) => {
      state.userData = actions.payload;
    },
    updatetoken: (state, actions) => {
      state.token = actions.payload;
    },
    updateAlertData: (state, actions) => {
      state.alertData = actions.payload;
    },
  },
});

export const { startLoading, stopLoading, updateUserData, updateAlertData, updatetoken } = appSlice.actions;
